import { CUSTOM_AUTH_STATUS } from 'appConstants/auth';
import { FLOW_STATUS_CUSTOM_EVENTS } from 'appConstants/customEvents';
import { OPEN_ROUTES, ROUTES } from 'appConstants/routing';
import obs from 'logging/observability/observability';
import { getActionURL, getOptions } from 'utilities/apiUtils';

let data: Response | { error: unknown };

const dispatchSendFlowStatusEvent = () => {
  const flowEvent = new CustomEvent(FLOW_STATUS_CUSTOM_EVENTS.SEND_FLOW_STATUS, {
    detail: data,
  });
  document.dispatchEvent(flowEvent);
};

const getCustomResponse = (status: string) => {
  const responseBlob = new Blob([JSON.stringify({ status }, null, 2)], {
    type: 'application/json',
  });
  return new Response(responseBlob, { status: 200, statusText: 'OK' });
};

const fetchFlowStatusEvent = async () => {
  try {
    const appUrl = new URL(window.location.href);
    const pathName = appUrl.pathname;

    /* If it is an open route, don't call GET flow API, just return the status in a Response object
     * The open Route component will handle the respective functionality
     * If its not an open route and not the base route, then show page not found screen
     */
    if (pathName in OPEN_ROUTES) {
      data = getCustomResponse(OPEN_ROUTES[pathName].status);
      return;
    } else if (pathName !== ROUTES.BASE && pathName !== ROUTES.DOWNLOAD) {
      data = getCustomResponse(CUSTOM_AUTH_STATUS.PAGE_NOT_FOUND);
      return;
    }

    // If there is no flowId in the URL, then show Forbid Entry screen
    if (!appUrl.searchParams.has('flowId')) {
      data = getCustomResponse(CUSTOM_AUTH_STATUS.FORBID_ENTRY);
      return;
    }

    // Set FlowId as a unique identifier for the current flow in New Relic
    obs.setUserId(appUrl.searchParams?.get('flowId') ?? null);

    const url = getActionURL(null);
    const option = getOptions(null);

    obs.setAttributeToCurrentInteraction('action', 'getFlow');
    data = await fetch(url, option);
  } catch (error) {
    data = { error };
  } finally {
    /* Use case 2: Main script is already loaded and promise is not yet resolved so use case1 doesn't work.
      Trigger the event again to send the data once try/catch execution is complete.
      */
    dispatchSendFlowStatusEvent();
  }
};

// Listens to the event triggered from Auth component for getting the flow data fetched
// Use case 1: When the get flow promise is resolved before the main script loads then this event is required to send the fetched data
document.addEventListener(FLOW_STATUS_CUSTOM_EVENTS.FETCH_FLOW_STATUS, dispatchSendFlowStatusEvent, { once: true });

// Trigger this as soon as the script loads to fetch the flow status and store in data
fetchFlowStatusEvent();

export { fetchFlowStatusEvent };
